export const environment = {
  name: 'development',
  production: true,
  insightKey: 'e718ffc4-7665-479a-88d8-1e8f8ec66f3e',
  domainPatientAPI: 'https://ofix-to-gtw-locked.azure-api.net/pat/v1',
  domainUserAPI: 'https://ofix-to-gtw-locked.azure-api.net/usr/v1',
  domainImageAPI: 'https://ofix-to-gtw-locked.azure-api.net/img/v1',
  domainRayAPI: 'https://ofix-to-gtw-locked.azure-api.net/ray/v1',
  domainIntegrationAPI: 'https://ofix-to-gtw-locked.azure-api.net/int/v1',
	domainTlhexAPI: 'https://ofix-to-gtw-locked.azure-api.net/tlhex/v1',
	domainAiAPI: 'https://ofix-to-gtw-locked.azure-api.net/ai/v1',
	sseAPI: 'https://ofix-to-gtw-locked.azure-api.net/sse',
  cdnUrl: 'https://cdndev.orthonext.app/dev',
  b2cTenantName: 'tlhexdevIDP',
  b2cTenantId: '8ccea850-ae88-4811-a8ea-cf0e7cbcc2ee',
  b2cSignInPolicyId: 'B2C_1_OnlySignIN2',
  b2cResetPasswordPolicyId: 'B2C_1_passwordreset1',
  b2cChangePasswordPolicyId: 'B2C_1A_PasswordChange',
  b2cClientId: '51f6baab-8cd4-4606-a3c1-32097005e84a',
  b2cApplicationScope: 'openid profile https://tlhexdevIDP.onmicrosoft.com/ofixdev/user_impersonation',
  appSSOutUriApi: 'https://ofix-to-gtw-locked.azure-api.net/services/appssouturi',
  idleTimeToEndSession: 1800,
  buildVer: 'OrthoNext-PatientCase FE DEV CI-CD_24255.01',
  validMIMETypes: ['image/png', 'image/jpeg'],
  maxAttachmentSize: 10485760,
	resetSubscriptionTimeout: 30000,
  nextraySite: 'https://nextraydev.orthonext.app',
  fitboneSite: 'https://fitbonedev.orthonext.app',
	tlhexSite: 'https://tlhexdev.orthonext.app/tlhex',
  tlhexRaySite: 'https://tlhexdev.orthonext.app/hexray',
  tlhexArchiveSite: 'https://tlhexdev.orthonext.app/archive',
  tlhexViewSite: 'https://tlhexdev.orthonext.app/view',
  legalTermsSite: 'https://requestdev.orthonext.app/legal-terms',
	legalTermsAcceptSite: 'https://requestdev.orthonext.app/legal-terms-acceptance',
  aboutSite: 'https://cdndev.orthonext.app/dev/about/about-en.html',
  guideSite: {
    baseUrl: 'https://cdndev.orthonext.app/dev/Guide',
    list: 'https://cdndev.orthonext.app/dev/Guide/guide_list.json'
  },
	helpSite: {
		baseUrl: 'https://cdndev.orthonext.app/dev/Help/Patient20',
		fileNames: 'https://cdndev.orthonext.app/dev/Help/Patient20/file_names.json'
	},
  customerCareEmail: 'orthonext@orthofix.com',
	myhexplanSite: 'https://treatmentsupportportal.azurewebsites.net',
	eifuSite: 'https://ifu.orthofix.it',
	cookieName: '_iub_cs-60532175',
	cookiePolicyId: 60532175,
	cookieSiteId: 3595577
};
