import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { LanguageService } from "../core";

const SEPARATOR = ' > ';


/**
 * This service handles page title
 */
@Injectable()
export class TitleService {
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private titleService: Title,
		private langSrv: LanguageService
	) { }

	static ucFirst(text: string) {
		if (!text) { return text; }
		text = text.split('?')[0];
		return text.charAt(0).toUpperCase() + text.slice(1);
	}

	/**
	* Set page title
	*/
	init() {
		const prefixTitle = this.langSrv.labels.APP_PREFIX_TITLE;
		this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map(() => {
				let route = this.activatedRoute;
				while (route.firstChild) { route = route.firstChild; }
				return route;
			}),
			filter((route) => route.outlet === 'primary'),
			map((route) => route.snapshot),
			map((snapshot) => {
				return this.router.url.split('/').reduce((acc, frag) => {
					if (acc && frag) { acc += SEPARATOR; }
					return acc + TitleService.ucFirst(frag);
				});
			}))
			.subscribe((pathString) => this.titleService.setTitle(`${prefixTitle}${pathString}`));
	}

}
