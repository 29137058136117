import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SseService } from '@ortho-next/sse';
import { ErrorMessageBindingStrategy, ReactiveFormConfig, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { BehaviorSubject, Observable, Subscription, filter, finalize, first, forkJoin, map, of, take, takeWhile, tap, throwError, timer } from 'rxjs';
import { environment } from '../../environments/environment';
import { StateTypes } from '../../nextray/Core/Context';
import { AnatomicalSideEnum, BoneTypeEnum, LanguageService, LoaderService, PatientBase, Plan, PlanTypeEnum, ProductTypeEnum, ReferenceTypeEnum, ToastService } from '../core';
import { PlanForm } from '../models';
import { ConfirmationComponent } from '../shared';
import { AttachmentService } from './attachment.service';
import { PatientService } from './patient.service';
import { PlanService, getAnatomicalSite } from './plan.service';
import { TlhexService } from './tlhex.service';


/**
 * This service handles plan form page.
 */
@Injectable()
export class PlanFormService {

	private _planForm = new BehaviorSubject<RxFormGroup>(null);

	isEdit: boolean;
	patient: PatientBase;
	plan: Plan;
	userId: string;

	productList: string[] = [];
	noProductsEnabled: boolean;
	isIwrenchVisible: boolean;
	isIwrenchEnabled: boolean;

	startAnalysis: boolean;
	isSaving: boolean;
	resetKey: string;
	activatedRoute: ActivatedRoute;

	private _apImageSubscription: Subscription;
	private _ltImageSubscription: Subscription;
	private _labels: any;

	constructor(
		private formBuilder: RxFormBuilder,
		private patientSrv: PatientService,
		private planSrv: PlanService,
		private attachSrv: AttachmentService,
		private tlhexSrv: TlhexService,
		private loaderSrv: LoaderService,
		private toastSrv: ToastService,
		private langSrv: LanguageService,
		private sse: SseService,
		private router: Router,
		private modalSrv: NgbModal
	) { }

	// #region getter
	private get _formValue(): PlanForm { return this._planForm?.value?.value };

	private get _formCtrl(): { [key in keyof PlanForm]: AbstractControl } {
		return this._planForm?.value?.controls as { [key in keyof PlanForm]: AbstractControl };
	}

	/**
	 * Check if bone section is disabled.
	 */
	get isBoneBoxDisabled(): boolean { return !this._formCtrl || this._formCtrl.planId.invalid || this._formCtrl.postOperative.invalid || this._formCtrl.planType.invalid || this._formCtrl.referenceType.invalid; }

	/**
	 * Check if image section is disabled.
	 */
	get isImagesBoxDisabled(): boolean { return this.isBoneBoxDisabled || (this._formCtrl.boneType.invalid && !this.attachSrv.hasAttachments); }

	/**
	 * Get form reference type.
	 */
	get referenceType(): ReferenceTypeEnum { return this._formValue?.referenceType; }

	/**
	 * Check if save button is disabled.
	 */
	get isSaveDisabled(): boolean {
		return !this._planForm?.value?.valid || this.isSaving || (this.plan?.isCheckUp && !this.productList?.length);
	}

	/**
	 * Check is start analysis button is disabled.
	 */
	get isStartAnalysisDisabled(): boolean {
		return !this._planForm?.value?.valid || this.isSaving || !this.productList?.length || (this.plan?.isCheckUp && this.isFullLegNoImages);
	}

	private get isFullLegNoImages(): boolean {
		return this._formValue.boneType === BoneTypeEnum.LongLeg && (!this._formValue.apImageGuid || !this._formValue.ltImageGuid);
	}

	getForm(): Observable<RxFormGroup> { return this._planForm.asObservable().pipe(first(form => !!form)); };

	// #endregion

	// #region init 

	/**
	* Navigate to patient list page.
	*/
	returnToPatientList() {
		this.router.navigateByUrl("/list");
	}

	/**
	* Navigate to patient page 
	*/
	returnToPatient(): void {
		this.router.navigate(["/patient/edit"], { queryParams: { patientGuid: this.patient.id } });
	}

	/**
	 * Init plan form page.
	 */
	initForm(activatedRoute: ActivatedRoute): Observable<boolean> {
		this._initFormConfig();
		this._labels = this.langSrv.labels;
		this.activatedRoute = activatedRoute;
		const route = activatedRoute.snapshot;
		const path = route.routeConfig.path;
		if (path === 'plan/new') {
			this.isEdit = false;
			const patientGuid = route.queryParams['patientGuid'];
			if (!patientGuid) throw new Error('Patient Guid is required');
			return this._initCreateForm(patientGuid);
		} else if (path === 'plan/edit') {
			this.isEdit = true;
			const planGuid = route.queryParams['planGuid'];
			if (!planGuid) throw new Error('Plan Guid is required');
			return this._initEditForm(planGuid);
		} else {
			return throwError(() => new Error('Plan form page loading error'));
		}
	}

	private _initFormConfig() {
		ReactiveFormConfig.set({
			"validationMessage": this.langSrv.labels,
			"errorMessageBindingStrategy": ErrorMessageBindingStrategy.OnDirtyOrTouched
		});
	}

	private _initCreateForm(patientGuid: string): Observable<boolean> {
		this.loaderSrv.show();
		return this.patientSrv.getPatient(patientGuid).pipe(
			filter(pat => !!pat),
			tap(pat => {
				this.patient = pat;
				this.userId = pat.userGuid;
				this.patientSrv.setCurrentPatient(pat.id, pat.userGuid);

				const form = <RxFormGroup>this.formBuilder.formGroup(PlanForm);
				form.controls.patientGuid.setValue(this.patient.id);
				this._planForm.next(form);

				this.initImages(this.patient.id, this.userId, null);
				this.updateProductList();
			}),
			map(() => true),
			finalize(() => this.loaderSrv.hide())
		);
	}

	private _initEditForm(planGuid: string): Observable<boolean> {
		this.loaderSrv.show();
		return this.planSrv.getPlan(planGuid).pipe(
			filter(plan => !!plan),
			tap(plan => {
				if (plan.isPublished || plan.isRevoked) {
					this._openInvalidPlanModal();
				} else {
					this.plan = plan;
					this.patient = plan.patient;
					this.userId = plan.userGuid;
					this.patientSrv.setCurrentPatient(this.patient.id, this.userId);

					const form = <RxFormGroup>this.formBuilder.formGroup(PlanForm, this.planSrv.createPlanForm(plan));
					form.controls.patientGuid.setValue(this.patient.id);
					this._planForm.next(form);

					this.initImages(this.patient.id, this.plan.userGuid, this.plan);
					this.updateProductList();
				}
			}),
			map(() => true),
			finalize(() => this.loaderSrv.hide())
		);
	}

	private _openInvalidPlanModal(): void {
		const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
			centered: true, backdrop: 'static'
		});
		(modalRef.componentInstance as ConfirmationComponent).config = {
			title: this._labels['INVALID_PLAN_MODAL_TITLE'],
			message: this._labels['INVALID_PLAN_MODAL_MESSAGE'],
			confirmBtn: this._labels['INVALID_PLAN_MODAL_CONFIRM_BTN'],
			isCancelBtn: false
		};
		modalRef.result.then(() => this.returnToPatientList());
	}

	private initImages(patientGuid: string, userId: string, plan: Plan) {
		const getMountingParams = plan?.isCheckUp ? [this.tlhexSrv.getMountingParameters(plan.id, plan.userGuid)] : [of(null)];
		forkJoin(getMountingParams).subscribe(([params]) => {
			if (this.plan) {
				this.attachSrv.updateCheckUpValidation(plan, params?.ringProximalId, params?.ringDistalId);
				this.attachSrv.setPlanImages(plan);
			} else {
				this.attachSrv.resetPlanImages();
			}
			this.attachSrv.updateAttachmentList(patientGuid, userId);
			this._apImageSubscription = this.attachSrv.getApImageId().subscribe(imageId => this._updateApImage(imageId));
			this._ltImageSubscription = this.attachSrv.getLtImageId().subscribe(imageId => this._updateLtImage(imageId));
		});
	}

	// #endregion

	// #region update

	/**
	 * Update available product list.
	 */
	updateProductList(): void {
		const planType: PlanTypeEnum = this._formValue.planType;
		const boneType: BoneTypeEnum = this._formValue.boneType;
		const hasAP: boolean = !!this._formValue.apImageGuid;
		const hasLT: boolean = !!this._formValue.ltImageGuid;
		const isPostOp: boolean = this._formValue.postOperative;
		if (planType !== null && boneType !== null && isPostOp !== null) {
			this.planSrv.getAvailableProducts(planType, boneType, hasAP, hasLT, isPostOp).subscribe(list => {
				this.productList = list;
				this._updateIwrench();
			});
		} else {
			this.productList = [];
			this._updateIwrench();
		}
	}

	private _updateIwrench(): void {
		this.isIwrenchEnabled = this.productList?.includes(ProductTypeEnum.Iwrench);
		if (!this.isIwrenchEnabled && !this.plan?.isCheckUp) {
			this._formCtrl.hasIwrench.setValue(false);
		}
	}

	private _updateApImage(imageId: string) {
		const apImageCtrl = this._formCtrl.apImageGuid;
		if (apImageCtrl.value !== imageId) {
			apImageCtrl.setValue(imageId);

			if (!this.plan?.isCheckUp) {
				this._updateDataFromImages();
			} else {
				this.updateProductList();
			}
		}
	}

	private _updateLtImage(imageId: string) {
		const ltImageCtrl = this._formCtrl.ltImageGuid;
		if (ltImageCtrl.value !== imageId) {
			ltImageCtrl.setValue(imageId);

			if (!this.plan?.isCheckUp) {
				this._updateDataFromImages();
			} else {
				this.updateProductList();
			}
		}
	}

	private _updateDataFromImages(): void {
		if (this._formCtrl.apImageGuid.value) {
			this.attachSrv.getApImage().pipe(first()).subscribe(img => this._updateAnatomicalSite(img.boneSide, img.boneType));
		} else if (this._formCtrl.ltImageGuid.value) {
			this.attachSrv.getLtImage().pipe(first()).subscribe(img => this._updateAnatomicalSite(img.boneSide, img.boneType));
		} else {
			this._updateAnatomicalSite(null, null);
		}
	}

	private _updateAnatomicalSite(side: AnatomicalSideEnum, bone: BoneTypeEnum): void {
		this._formCtrl.boneType.setValue(bone);
		this._formCtrl.side.setValue(side);
		this._formCtrl.anatomicalSite.setValue(getAnatomicalSite(side, bone));
		this._formCtrl.anatomicalSite.markAsTouched();
		this.updateProductList();
	}

	// #endregion

	// #region save

	/**
	* Open confirmation modal before returning in patient list page.
	*/
	openConfirmModal() {
		if (!this.modalSrv.hasOpenModals()) {
			const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
				centered: true, backdrop: 'static'
			});
			(modalRef.componentInstance as ConfirmationComponent).config = {
				title: this._labels['PLAN_FORM_COMPONENT_CANCEL_MODAL_TITLE'],
				message: this._labels['PLAN_FORM_COMPONENT_CANCEL_MODAL_MESSAGE'],
				cancelBtn: this._labels['PLAN_FORM_COMPONENT_CANCEL_MODAL_NO'],
				confirmBtn: this._labels['PLAN_FORM_COMPONENT_CANCEL_MODAL_YES']
			};
			modalRef.result.then(() => this.returnToPatientList()).catch(error => false);
		}
	}

	/**
	* Open modal to confirm analysis reset.
	*/
	openResetAnalysisModal(startAnalysis: boolean) {
		if (this.plan != null && this._hasToResetAnalysis()) {
			const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
				centered: true, backdrop: 'static'
			});
			(modalRef.componentInstance as ConfirmationComponent).config = {
				title: this._labels['PLAN_FORM_COMPONENT_RESET_ANALYSIS_MODAL_TITLE'],
				message: this._labels['PLAN_FORM_COMPONENT_RESET_ANALYSIS_MODAL_MESSAGE'],
				cancelBtn: this._labels['PLAN_FORM_COMPONENT_RESET_ANALYSIS_MODAL_NO'],
				confirmBtn: this._labels['PLAN_FORM_COMPONENT_RESET_ANALYSIS_MODAL_YES']
			};
			modalRef.result.then(() => this._savePlan(startAnalysis)).catch(error => false);
		} else {
			this._savePlan(startAnalysis);
		}
	}

	private _hasToResetAnalysis(): boolean {
		if (this.plan.isCheckUp) return false;

		const isApChanged = this._formValue.apImageGuid != this.plan.apImageGuid;
		const isLtChanged = this._formValue.ltImageGuid != this.plan.ltImageGuid;
		const isSiteChanged = this.plan.side != null && (this._formValue.side != this.plan.side || this._formValue.boneType != this.plan.boneType);
		const isStepChanged = this._formValue.postOperative != this.plan.isPostOperative;
		const isTypeChanged = this._formValue.planType != this.plan.type;
		const isReferenceChanged = this._formValue.referenceType != this.plan.referenceType;
		const isBoneTypeChanged = this._formValue.boneType != this.plan.boneType;
		const isIwrenchChanged = this._formValue.hasIwrench != this.plan.hasIWrench;
		return isApChanged || isLtChanged || isSiteChanged || isStepChanged || isTypeChanged || isReferenceChanged || isBoneTypeChanged || isIwrenchChanged;
	}

	private _savePlan(startAnalysis: boolean) {
		this.startAnalysis = startAnalysis;
		this.loaderSrv.show();
		if (!this.isEdit) { // New Plan
			this.isSaving = true;
			this.planSrv.savePlanForm(this._formValue).pipe(
				finalize(() => { this.isSaving = false; this.loaderSrv.hide(); })
			).subscribe({
				next: (plan: Plan) => {
					this.toastSrv.showSuccess(this._labels['PLAN_FORM_COMPONENT_SAVE_MESSAGE']);

					// update form
					this.plan = plan;
					this.attachSrv.setPlanImages(plan);

					// update url and/or navigate
					this._updateQueryParams(plan.id);
					if (this.startAnalysis) this._navigateToAnalysis(plan, false);
					this.startAnalysis = false;
				},
				error: (err: Error) => {
					this.toastSrv.showError(this._labels['PLAN_FORM_COMPONENT_ERROR_MESSAGE']);
					this._handlePlanIdNotUnique(err);
					this.startAnalysis = false;
				}
			});
		} else { // Update Plan
			const hasToResetAnalysis = this._hasToResetAnalysis();
			this.isSaving = true;
			if (hasToResetAnalysis) {
				this._handleResetEvents(this._formValue);
			}
			this.planSrv.updatePlan(this.plan, this._formValue).subscribe({
				next: () => {
					// update form
					this.plan = this.planSrv.updatePlanModel(this.plan, this._formValue);
					this.attachSrv.setPlanImages(this.plan);

					if (!hasToResetAnalysis) {
						this.startAnalysis && this._navigateToAnalysis(this.plan, false);

						this.toastSrv.showSuccess(this._labels['PLAN_FORM_COMPONENT_UPDATE_MESSAGE']);
						this.loaderSrv.hide();
						this.isSaving = false;
						this.startAnalysis = false;
					}
				},
				error: (err: Error) => {
					this.toastSrv.showError(this._labels['PLAN_FORM_COMPONENT_ERROR_MESSAGE']);
					this._handlePlanIdNotUnique(err);
					this._clearResetEvents();
					this.loaderSrv.hide();
					this.isSaving = false;
					this.startAnalysis = false;
				}
			});
		}
	}

	/**
	 * Handle subscription to analysis reset event.
	 */
	private _handleResetEvents(planForm: PlanForm): void {
		if (!planForm.apImageGuid && !planForm.ltImageGuid) {
			this.resetKey = `OrthoNext.Tlhex.Reset.${this.plan.id}`;
		} else {
			this.resetKey = `OrthoNext.Ray.Reset.${this.plan.id}`;
		}
		console.log('Resetting... ' + this.resetKey);
		this.sse.subscribe(this.resetKey, (success: boolean) => {
			console.log('Resetted: ' + success);
			if (success) {
				// TODO: update plan object from UpdatePlan API
				this.plan.modelStatus = 0;
				this.toastSrv.showSuccess(this._labels['PLAN_FORM_COMPONENT_UPDATE_MESSAGE']);
				this.startAnalysis && this._navigateToAnalysis(this.plan, true);
			} else {
				this.toastSrv.showError(this._labels['PLAN_FORM_COMPONENT_RESET_ERROR']);
			}
			this._closeResetSubscription();
		});
		// timeout reset subscription
		timer(0, environment.resetSubscriptionTimeout / 10).pipe(
			takeWhile(() => !!this.resetKey),
			take(10),
		).subscribe({
			complete: () => {
				if (this.resetKey) {
					console.log('Not Resetted');
					this.toastSrv.showError(this._labels['PLAN_FORM_COMPONENT_RESET_ERROR']);
					this._closeResetSubscription();
				}
			}
		});
	}

	private _closeResetSubscription(): void {
		this._clearResetEvents();
		this.loaderSrv.hide();
		this.isSaving = false;
		this.startAnalysis = false;
	}

	private _clearResetEvents(): void {
		if (this.resetKey) {
			this.sse.unsubscribe(this.resetKey);
			this.resetKey = null;
		}
	}

	private _updateQueryParams(planId: string) {
		this.router.navigate(
			['/plan/edit'],
			{
				relativeTo: this.activatedRoute,
				queryParams: { patientGuid: null, planGuid: planId },
				queryParamsHandling: 'merge'
			});
	}

	private _navigateToAnalysis(plan: Plan, hasToReset: boolean): void {
		let appRedirectUrl;
		if (!plan.apImageGuid && !plan.ltImageGuid) { //tlhex manual
			appRedirectUrl = environment.tlhexSite;
		} else if (!plan.modelStatus || hasToReset) { //new or renew plan
			appRedirectUrl = environment.nextraySite;
		} else if (plan.modelStatus === StateTypes.RPM) { //fitbone
			appRedirectUrl = environment.fitboneSite;
		} else if (plan.modelStatus === StateTypes.tlhex) { //tlhex
			appRedirectUrl = environment.tlhexRaySite;
		} else if (plan.modelStatus === StateTypes.tlhexSchedule) { //tlhex post hexray
			appRedirectUrl = environment.tlhexSite;
		} else {
			appRedirectUrl = environment.nextraySite;
		}

		window.location.href = appRedirectUrl + '?caseGuid=' + plan.id;
	}

	private _handlePlanIdNotUnique(err: Error) {
		if (err.message === "PlanNumberNotUnique") {
			this._formCtrl.planIdNotUnique.setValue(true);
			this._formCtrl.planId.setValue(this._formValue.planId);
		}
	}

	// #endregion

	// #region reset

	/**
	 * Reset plan form page.
	 */
	reset(): void {
		this.isEdit = null;
		this._planForm.next(null);
		this.patient = null;
		this.plan = null;
		this.userId = null;

		this.productList = [];
		this.noProductsEnabled = null;
		this.isIwrenchVisible = null;
		this.isIwrenchEnabled = null;

		this.startAnalysis = null;
		this.isSaving = null;
		this.resetKey = null;
		this.activatedRoute = null;

		this._apImageSubscription?.unsubscribe();
		this._ltImageSubscription?.unsubscribe();

		this.attachSrv.resetAttachmentList();
		this.attachSrv.resetPlanImages();
		this.patientSrv.setCurrentPatient(null, null);
		this._clearResetEvents();
	}

	// #endregion

}